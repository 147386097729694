/* Customize the scrollbar track */
body::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

/* Customize the scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background-color: #808080; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}
.ms-btn img {
  margin-right: 10px; /* Adjust the margin between the icon and text */
}
.login-with-btn div {
  padding: 0px !important;
}
.login-with-btn {
  display: flex; /* Add flex display */
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Align items horizontally center */
  transition: background-color 0.3s, box-shadow 0.3s !important;
  padding: 12px 16px;
  border: none;
  border-radius: 5px !important;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25) !important;
  color: #757575;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  background-color: white;
  background-repeat: no-repeat;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}